import React from 'react'
import Layout from 'components/layout'
import Meta from 'components/meta'
import { siteMetadata } from '../../../gatsby-config'
import Lines from 'components/lines'
import Light from 'components/colorLight'
import style from 'scss/pages/posizioniAperte.module.scss'
import FormContactCareers from 'components/formContactCareers'

class AccountWebProjectManager extends React.Component {
  render() {
    const { location, data } = this.props

    return (
      <div ref={this.page} className={style.page}>
        <Layout location={location}>
          <Meta
            site={siteMetadata}
            title="Account e Web Project Manager"
            description="Sei un Account e Web Project Manager? Supportarci nello sviluppo del nostro business. Inviaci la tua candidatura."
          />

          <Lines />

          <div className={`container-fluid ${style.page__content}`}>
            <div className={`row`}>
              <div className={`offset-md-2 col-md-2`}>
                <p
                  className={`has-color-primary-negative ${style.page__content__claimLeft}`}
                >
                  ACCOUNT
                  <br />
                  <Light>e web project manager</Light>
                </p>
              </div>
              <div
                className={`offset-md-1 col-md-7 no-padding ${style.page__content__fullScreenSlide}`}
              >
                <div>
                  <h3>Cerchiamo un Account e Web Project Manager</h3>

                  <p>
                    Al momento stiamo ricercando un Web Project Manager da
                    inserire nel nostro team. La figura deve avere competenze
                    trasversali nell'ambito web ma è preferibile che abbia un
                    background tecnico.
                  </p>

                  <p>Responsabilità:</p>
                  <ul className="lista_generica lista_competenze">
                    <li>
                      Pianificare e gestire tutte le fasi operative che
                      intercorrono tra la decisione di sviluppare il nuovo
                      progetto e il suo rilascio
                    </li>
                    <li>
                      Raccolta dei requisiti e delle esigenze di progetto e
                      stesura documentazione
                    </li>
                    <li>Gestione e rispetto della timeline di progetto</li>
                    <li>
                      Coordinamento tra team interni (Dev, designers, Web
                      marketers, Account) e fornitori esterni
                    </li>
                    <li>Assicurarsi della soddisfazione del cliente</li>
                  </ul>
                  <p>Requisiti:</p>
                  <ul className="lista_generica lista_competenze">
                    <li>Ottima capacità relazionale</li>
                    <li>
                      Laurea in discipline tecnico/scientifiche, preferibilmente
                      Informatica o simili
                    </li>
                    <li>
                      Esperienza di 2/3 anni nella gestione di progetti software
                      o web (siti web o web appplications)
                    </li>
                    <li>
                      Forte attenzione al dettaglio e alla qualità delle
                      attività svolte
                    </li>
                    <li>
                      Orientamento all'obiettivo e buona autonomia nel
                      raggiungerlo
                    </li>
                    <li>
                      Abilità di gestione di molteplici progetti con l'obiettivo
                      di rispettare le consegne
                    </li>
                    <li>
                      Capacità di lavorare in team e buona attitudine alla
                      flessibilità
                    </li>
                    <li>Conoscenza della lingua inglese parlato e scritto</li>
                    <li>
                      Ottima dimestichezza con l'utilizzo dei principali cms (in
                      particolare Wordpress, Drupal)
                    </li>
                    <li>
                      Gradita conoscenza delle basi di UX design e principi SEO
                    </li>
                  </ul>
                  <p>Dettagli:</p>
                  <ul className="lista_generica lista_competenze">
                    <li>Sede: Veneto - Venezia Mestre</li>
                  </ul>

                  <h4 className={'title'}>Inviaci il curriculum</h4>
                  <FormContactCareers
                    position={'RIF.98 - Account e Web Project Manager'}
                  />
                </div>
                <div
                  className={`offset-md-5 col-md-7 no-padding ${style.page__content__fullScreenSlide__arrow}`}
                />
              </div>
            </div>
          </div>
        </Layout>
      </div>
    )
  }
}

export default AccountWebProjectManager
